<template>
  <div class="class-booking-select justify-content-between ml-1 mb-1">
    <b-form-checkbox
      v-model="isSelected"
      :disabled="isDisabled"
      switch
      class="custom-control-warning"
      @input="val => handleUpdate(val)"
    >
      <span :class="`text-nowrap text-body font-weight-bold ${isDisabled ? 'text-muted' : ''}`">{{ $t('flight.classBooking') }}</span>
    </b-form-checkbox>
  </div>
</template>

<script>

import { BFormCheckbox } from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    isSearchClassBooking: {
      type: Boolean,
      required: true,
    },
    isSearchMonthlyCheapestFare: {
      type: Boolean,
      required: true,
    },
    isGroupedItineraryResponse: {
      type: [Boolean, undefined],
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const isSelected = ref(false)

    watch(() => props.isSearchClassBooking, val => {
      if (val === true || val === false) {
        isSelected.value = val
      }
    }, { immediate: true })

    watch(() => [props.isSearchMonthlyCheapestFare, props.isGroupedItineraryResponse], ([monthlyCheapestFareVal, groupedItineraryResponseVal]) => {
      if (monthlyCheapestFareVal || groupedItineraryResponseVal) {
        isSelected.value = false
      }
    }, { immediate: true })

    function handleUpdate(val) {
      emit('update:isSearchClassBooking', val)
    }
    const isDisabled = computed(() => props.isSearchMonthlyCheapestFare || props.isGroupedItineraryResponse)
    return {
      isSelected,
      handleUpdate,
      isDisabled,
    }
  },
}
</script>

<style lang="scss" scoped>
.class-booking-select {
  margin-bottom: -7px !important;
}
</style>
